import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENVIRONMENT } from '$env/static/public';


Sentry.init({
  environment: PUBLIC_ENVIRONMENT,
  dsn: 'https://c3bb0d76d934eb19eaddf7791d28365f@o4507588969627648.ingest.de.sentry.io/4507588972118096',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: .05,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = PUBLIC_ENVIRONMENT === 'development' ? undefined : handleErrorWithSentry();
